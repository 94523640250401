import React, { Component } from 'react';
import { Row, Col } from "antd";
import parkList from '../morkData/parkListData'
import CardTitle from '../components/ParkStatistics/CardTitle'
import CompanyStat from '../components/ParkStatistics/CompanyStat'
import { convertToHtml } from '../utils/utils' 
import './parkData.less'

const listedType =  [
  {
    "id": 1,
    "name": "主板上市"
  },
  {
    "id": 2,
    "name": "新三板上市"
  },
  {
    "id": 3,
    "name": "中小板上市"
  },
  {
    "id": 4,
    "name": "创业板上市"
  },
  {
    "id": 5,
    "name": "科创板上市"
  },
  {
    "id": 6,
    "name": "已退市"
  },
  {
    "id": 7,
    "name": "新四板上市"
  }
]

class ParkData extends Component {
    constructor(props) {
      super(props);
      const { match: { params: { id, key } } } = props
      const type = parkList.find(ele => ele.key === key) || {}
      const park = (type.parks && type.parks.find(ele => Number(ele.park_id) === Number(id))) || {}
      this.state = {
        parkDetail: park,
        id, 
        key
      }
    }
  
    goControlCenter = () => {
      const { history } = this.props;
      const { id, key } = this.state
      history.replace({pathname: `/`, state: { id, key }});
    }

    onClick = () => {}

    render() {
      const { parkDetail={} } = this.state
      const { basicInfo={}, statisticsTalents={}, park={} } = parkDetail
      const { companyQualifications=[], publicCompanyTypes=[] } = basicInfo
      const { hostIntro='' } = park
      const talentPlans = statisticsTalents.talentPlans && statisticsTalents.talentPlans.filter(v => v.count > 0)
        .map(v => {
          return {
            count: v.count,
            target: "talent",
            id: Number(v.conf_id),
            name: v.talentPlanName,
          };
        });
      const zizhi = companyQualifications.filter(v => v.count > 0).map(v => {
        return {
          count: v.count,
          target: "conf_ids",
          id: Number(v.conf.conf_id),
          ...v.conf,
        };
      });
      const listedCompanies = publicCompanyTypes.map(v => {
        const target = listedType.find(d => d.id === v.id);
        return {
          ...v,
          target: "publicCompanyType",
          name: target && target.name,
          // count: v.count,
          id: target && target.id && Number(target.id),
        };
      }).filter(v => v.name && v.count > 0);
      const companyData = {
        ...basicInfo,
        companyQualifications: zizhi,
        publicCompanyTypes: listedCompanies,
        talentPlans,
      }
      return (
        <div className='parkData'>
          <div className='head'>
            <div>{parkDetail.name}</div>
          </div>

          <div className='bodyArea'>
            <div className='dataArea'>
              <Row>
                <Col sm={24} xl={12}>
                  <div style={{ padding: "24px 32px" }}>
                    <CompanyStat data={companyData} />
                  </div>
                </Col>
                <Col sm={24} xl={12}>
                  <div style={{ padding: "40px"}}>
                    <CardTitle title="园区简介" />
                    <div style={{ marginTop: "20px", padding: "32px"}} className="textArea">{(hostIntro && convertToHtml(hostIntro)) || ''}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="backControlBg" onClick={this.goControlCenter}>
            <div className="backControl">
              <div>返回</div>
              <div>总控台</div>
            </div>
          </div>
        </div>
      )
    }
  }
  
  export default ParkData