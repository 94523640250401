import React from 'react';
import { ConfigProvider } from "antd";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import ControlCenter from './pages/controlCenter'
import "moment/locale/zh-cn";
import ParkData from './pages/parkData'

const App = (
  <ConfigProvider locale={zh_CN}>
    <BrowserRouter>
      <Switch>        
        <Route path="/park/:key/:id" component={ParkData} />        
        <Route path="/" component={ControlCenter} />
      </Switch>
    </BrowserRouter>
  </ConfigProvider>
);

export default App;
