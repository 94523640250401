import React from "react";
import { convertRawToHTML } from "braft-convert";

export const convertToHtml = text => {
  let innerHtml = null;
  if (text) {
    if (text.indexOf("entityMap") > -1) {
      innerHtml = convertRawToHTML(JSON.parse(text));
    } else if (text && text.indexOf("<p>") >= -1) {
      innerHtml = text;
    }
  }
  const createMarkup = () => {
    return { __html: innerHtml };
  };
  return <div dangerouslySetInnerHTML={createMarkup()} />;
};