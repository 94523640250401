import React, { Component } from 'react';
import { Carousel } from "antd";
import parkList from '../morkData/parkListData'
import leftBTN from "../assets/image/leftBTN.png";
import rightBTN from "../assets/image/rightBTN.png";
import enterpriseGuangzhu from "../assets/image/guangzhu.gif";
import enterpriseRotate from "../assets/image/zhuanzhuanzhuan.png";
import "antd/dist/antd.css";
import './controlCenter.less'

class ControlCenter extends Component {
  constructor(props) {
    super(props);
    // const { history: { location: { state={} } } } = props
    // const { id='', key='' } = state
    // console.log(id, key)
    this.state = {
      showData: parkList,
      currentShow: parkList[0].key,
      pid: null
    }
    this.carouselRef = null;
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  slickGoTo = index => {
    this.carouselRef.slick.slickGoTo(index);
  };

  slickNext = () => {
    this.carouselRef.slick.slickNext();
  };

  slickPrev = () => {
    this.carouselRef.slick.slickPrev();
  };

  goPark = (id, key) => {
    const { history } = this.props;
    history.push(`/park/${key}/${id}`);
  }

  render() {
    // const { pid } = this.props
    const { currentShow, showData, pid } = this.state;
    const curParkId = Number(pid || 0);
    console.log(curParkId)

    const imgList = showData.map(ele => (
      <div key={ele.key} className="imgArea">
        <div className="imgPositon1">
          <img src={ele.img1} alt={ele.type} style={{ width: "100%" }} />
        </div>
        <div className="imgPositon2">
          <img src={ele.img2} alt={ele.type} style={{ width: "100%" }} />
        </div>
        <div className="enterprise_guangzhu">
          <img src={enterpriseGuangzhu} alt="" />
        </div>
        <div className="enterprise_rotate">
          <img src={enterpriseRotate} alt="" />
        </div>
        <div className="enterprise_rotate" style={{ top: "175px" }}>
          <img className="opacityImg" src={enterpriseRotate} alt="" />
        </div>
      </div>
    ));
    const showPark = showData.filter(ele => ele.key === currentShow);
    const parkList =
      showPark &&
      showPark[0].parks.map(item => {
        return (
          <div
            key={item.name}
            className="parkItem"
            onClick={() => this.goPark(item.park_id, showPark && showPark[0] && showPark[0].key)}
            ischecked={String(Number(item.park_id) === curParkId)}
            title={item.name}
            onMouseEnter={() => {
              this.carouselRef.slick.slickPause();
            }}
            onMouseLeave={() => {
              this.carouselRef.slick.slickPlay();
            }}
          >
            {(item.area && `${item.area} · `) || ""}
            {item.name}
          </div>
        );
      });
    const tabs = showData.map((ele, index) => {
      const className = currentShow === ele.key ? "currentTab" : "defaultTab"
      return (
        <div
          className={className}
          onClick={this.slickGoTo.bind(this, index)}
          key={index.toString()}
        >
          <span>{ele.type}</span>
        </div>
      );
    });
    return (
      <div className="controlCenter">
        <div className="tabs">{tabs}</div>
        <div className="btns">
          <div onClick={this.slickPrev}>
            <img src={leftBTN} alt="left" style={{ width: "100%" }} />
          </div>
          <div onClick={this.slickNext}>
            <img src={rightBTN} alt="right" style={{ width: "100%" }} />
          </div>
        </div>
        <div className="carousel">
          <Carousel
            dots={false}
            //  ref={this.carouselRef}
            ref={el => {
              this.carouselRef = el;
            }}
            afterChange={index => {
              this.setState({
                currentShow: showData[index].key,
              });
            }}
          >
            {imgList}
          </Carousel>
        </div>
        <div className="parkListArea">{parkList}</div>
      </div>
    )
  }
}

export default ControlCenter