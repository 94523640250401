import React from 'react';
import { Icon, Tooltip } from 'antd';
import "antd/dist/antd.css";
import './CardTitle.less';

const CardTitle = ({ title, info }) => {
  return (
    <span className="cardWrapper">
      <i className="iconfont icon-fill-hexagon" />
      <span className="title">{title}</span>
      {info && (
        <Tooltip placement="bottomLeft" title={info}>
          <Icon className="info" type="question-circle" theme="filled" />
        </Tooltip>
      )}
    </span>
  );
};

export default CardTitle;
