import React, { Component } from "react";
import { Card, Row, Col, Icon, Tooltip } from "antd";
import "./CompanyStat.less";

const CompanyItem = ({
  title,
  unit = "家",
  number,
  onClick,
  data = {},
  info,
}) => {
  let click = () => {};
  if (onClick) {
    click = onClick;
  }
  return (
    <span className="column">
      <span className="title" title={title}>
        {`${title}（${unit}）`}
        {info && (
          <Tooltip placement="bottomLeft" title={info}>
            <Icon
              className="info"
              type="question-circle"
              theme="filled"
            />
          </Tooltip>
        )}
      </span>
      <span className="number">
        <span style={{ cursor: "pointer" }} onClick={() => click(data)}>
          {number}
        </span>
      </span>
    </span>
  );
};

const rowCount = 3; // 一行三个col
const showRow = 4; // 默认显示4行数据

export default class CompanyStat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMore: false,
    };
  }

  countNumber = length => {
    const rowNum =
      length &&
      (length % rowCount === 0
        ? length / rowCount
        : Math.floor(length / rowCount) + 1);
    return rowNum;
  };

  showMoreBotton = () => {
    const { isShowMore } = this.state;
    return (
      <div className="showMore" onClick={this.clickBotton}>
        <div className={!isShowMore ? "zhankai" : "shouqi"} />
        <div className="text">{!isShowMore ? "展开" : "收起"}</div>
      </div>
    );
  };

  clickBotton = () => {
    const { isShowMore } = this.state;
    this.setState({
      isShowMore: !isShowMore,
    });
  };

  render() {
    const { data = {}, onClick } = this.props;
    const { isShowMore } = this.state;

    // 三种模块分别能够展示的所有行数
    const row = this.countNumber(data.publicCompanyTypes.length);
    const zizhiRow = this.countNumber(data.companyQualifications.length);
    const talentRow = this.countNumber(
      data.talentPlans && data.talentPlans.length
    );
    const allRow = row + zizhiRow + talentRow;

    // 上市企业数量（家）
    const total = data.publicCompanyTypes.reduce((res, cur) => {
      return res + cur.count;
    }, 0);

    let showlist1 = [];
    let hiddenlist1 = [];

    let showlist2 = [];
    let hiddenlist2 = [];

    let showlist3 = [];
    let hiddenlist3 = [];
    // 如果上市企业数据能展示的总行数 比 规定展示的行数 多
    if (row > showRow) {
      // 展示的最多数据
      showlist1 = data.publicCompanyTypes
        .slice(0, showRow * rowCount)
        .map(v => {
          return (
            <Col xs={24} sm={12} md={8} key={`listed-${v.id}`}>
              <CompanyItem
                title={v.name}
                number={v.count}
                data={v}
                onClick={onClick}
              />
            </Col>
          );
        });

      // 需要隐藏的数据
      hiddenlist1 = data.publicCompanyTypes
        .slice(showRow * rowCount, row * rowCount)
        .map(v => {
          return (
            <Col xs={24} sm={12} md={8} key={`listed-${v.id}`}>
              <CompanyItem
                title={v.name}
                number={v.count}
                data={v}
                onClick={onClick}
              />
            </Col>
          );
        });
      hiddenlist2 = data.companyQualifications.map(v => {
        return (
          <Col xs={24} sm={12} md={8} key={v.conf_id}>
            <CompanyItem
              title={v.name}
              number={v.count}
              data={v}
              onClick={onClick}
            />
          </Col>
        );
      });
      hiddenlist3 =
        data.talentPlans &&
        data.talentPlans.map(v => {
          return (
            <Col xs={24} sm={12} md={8} key={v.id}>
              <CompanyItem
                title={v.name}
                number={v.count}
                data={v}
                onClick={onClick}
                unit="人"
              />
            </Col>
          );
        });
    }
    if (row < showRow) {
      // 一模块展示的最多数据
      showlist1 = data.publicCompanyTypes.map(v => {
        return (
          <Col xs={24} sm={12} md={8} key={`listed-${v.id}`}>
            <CompanyItem
              title={v.name}
              number={v.count}
              data={v}
              onClick={onClick}
            />
          </Col>
        );
      });

      // 二模块 比 剩下规定可展示的行数 多
      if (zizhiRow > showRow - row) {
        showlist2 = data.companyQualifications
          .slice(0, (showRow - row) * rowCount)
          .map(v => {
            return (
              <Col xs={24} sm={12} md={8} key={v.conf_id}>
                <CompanyItem
                  title={v.name}
                  number={v.count}
                  data={v}
                  onClick={onClick}
                />
              </Col>
            );
          });
        hiddenlist2 = data.companyQualifications
          .slice((showRow - row) * rowCount, data.companyQualifications.length)
          .map(v => {
            return (
              <Col xs={24} sm={12} md={8} key={v.conf_id}>
                <CompanyItem
                  title={v.name}
                  number={v.count}
                  data={v}
                  onClick={onClick}
                />
              </Col>
            );
          });
        hiddenlist3 =
          data.talentPlans &&
          data.talentPlans.map(v => {
            return (
              <Col xs={24} sm={12} md={8} key={v.id}>
                <CompanyItem
                  title={v.name}
                  number={v.count}
                  data={v}
                  onClick={onClick}
                  unit="人"
                />
              </Col>
            );
          });
      }
      if (zizhiRow < showRow - row) {
        showlist2 = data.companyQualifications.map(v => {
          return (
            <Col xs={24} sm={12} md={8} key={v.conf_id}>
              <CompanyItem
                title={v.name}
                number={v.count}
                data={v}
                onClick={onClick}
              />
            </Col>
          );
        });

        // 三模块 比 剩下规定可展示的行数 多
        if (talentRow > showRow - row - zizhiRow) {
          showlist3 =
            data.talentPlans &&
            data.talentPlans
              .slice(0, (showRow - row - zizhiRow) * rowCount)
              .map(v => {
                return (
                  <Col xs={24} sm={12} md={8} key={v.id}>
                    <CompanyItem
                      title={v.name}
                      number={v.count}
                      data={v}
                      onClick={onClick}
                      unit="人"
                    />
                  </Col>
                );
              });
          hiddenlist3 =
            data.talentPlans &&
            data.talentPlans
              .slice(
                (showRow - row - zizhiRow) * rowCount,
                data.talentPlans.length * rowCount
              )
              .map(v => {
                return (
                  <Col xs={24} sm={12} md={8} key={v.id}>
                    <CompanyItem
                      title={v.name}
                      number={v.count}
                      data={v}
                      onClick={onClick}
                      unit="人"
                    />
                  </Col>
                );
              });
        }
        if (talentRow <= showRow - row - zizhiRow) {
          showlist3 =
            data.talentPlans &&
            data.talentPlans.map(v => {
              return (
                <Col xs={24} sm={12} md={8} key={v.id}>
                  <CompanyItem
                    title={v.name}
                    number={v.count}
                    data={v}
                    onClick={onClick}
                    unit="人"
                  />
                </Col>
              );
            });
        }
      }
      if (zizhiRow === showRow - row) {
        showlist2 = data.companyQualifications.map(v => {
          return (
            <Col xs={24} sm={12} md={8} key={v.conf_id}>
              <CompanyItem
                title={v.name}
                number={v.count}
                data={v}
                onClick={onClick}
              />
            </Col>
          );
        });
        hiddenlist3 =
          data.talentPlans &&
          data.talentPlans.map(v => {
            return (
              <Col xs={24} sm={12} md={8} key={v.id}>
                <CompanyItem
                  title={v.name}
                  number={v.count}
                  data={v}
                  onClick={onClick}
                  unit="人"
                />
              </Col>
            );
          });
      }
    }
    if (row === showRow) {
      showlist1 = data.publicCompanyTypes.map(v => {
        return (
          <Col xs={24} sm={12} md={8} key={`listed-${v.id}`}>
            <CompanyItem
              title={v.name}
              number={v.count}
              data={v}
              onClick={onClick}
            />
          </Col>
        );
      });
      hiddenlist2 = data.companyQualifications.map(v => {
        return (
          <Col xs={24} sm={12} md={8} key={v.conf_id}>
            <CompanyItem
              title={v.name}
              number={v.count}
              data={v}
              onClick={onClick}
            />
          </Col>
        );
      });
      hiddenlist3 =
        data.talentPlans &&
        data.talentPlans.map(v => {
          return (
            <Col xs={24} sm={12} md={8} key={v.id}>
              <CompanyItem
                title={v.name}
                number={v.count}
                data={v}
                onClick={onClick}
                unit="人"
              />
            </Col>
          );
        });
    }

    // const listed = data.publicCompanyTypes.map(v => {
    //  return (
    //         <Col xs={24} sm={12} md={8} key={`listed-${v.id}`}>
    //           <CompanyItem
    //             title={v.name}
    //             number={v.count}
    //             data={v}
    //             onClick={onClick}
    //           />
    //         </Col>
    //      )
    // });

    // 企业资质数量（家）
    const zizhiTotal = data.companyQualifications.reduce((res, cur) => {
      return res + cur.count;
    }, 0);

    // const zizhi = data.companyQualifications.map(v => {
    //   return (
    //     <Col xs={24} sm={12} md={8} key={v.conf_id}>
    //       <CompanyItem
    //         title={v.name}
    //         number={v.count}
    //         data={v}
    //         onClick={onClick}
    //       />
    //     </Col>
    //   );
    // });

    // 人才计划数量（人）
    const talentTotal =
      data.talentPlans &&
      data.talentPlans.reduce((res, cur) => {
        return res + cur.count;
      }, 0);
    // const talent =
    //   data.talentPlans &&
    //   data.talentPlans.map(v => {
    //     return (
    //       <Col xs={24} sm={12} md={8} key={v.conf_id}>
    //         <CompanyItem
    //           title={v.name}
    //           number={v.count}
    //           data={v}
    //           onClick={onClick}
    //           unit="人"
    //         />
    //       </Col>
    //     );
    //   });

    return (
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12}>
            <div className="company">
              <i className="iconfont icon-stat-qiye" />
              <CompanyItem
                title="企业数量"
                number={data.companyCount}
                onClick={onClick}
                data={{ target: "company" }}
              />
            </div>
          </Col>
        </Row>

        {/* <Row gutter={{ xl: 16, xxl: 24 }} style={{ marginBottom: 24 }}>
          {zizhi}
        </Row> */}

        <Row type="flex" gutter={{ xl: 16, xxl: 24 }}>
          {total ? (
            <Col xs={24} sm={24} md={6} className="haschild">
              <CompanyItem
                title="上市企业"
                number={total}
                info="上市企业总家数"
              />
            </Col>
          ) : null}
          <Col xs={24} sm={24} md={18}>
            <Row gutter={{ xl: 16, xxl: 24 }}>
              {showlist1}
              {isShowMore && hiddenlist1}
            </Row>
          </Col>
        </Row>

        <Row type="flex" gutter={{ xl: 16, xxl: 24 }}>
          {showRow > row && zizhiTotal ? (
            <Col xs={24} sm={24} md={6} className="haschild">
              <CompanyItem title="企业资质" number={zizhiTotal} />
            </Col>
          ) : (
            isShowMore &&
            zizhiTotal !== 0 && (
              <Col xs={24} sm={24} md={6} className="haschild">
                <CompanyItem title="企业资质" number={zizhiTotal} />
              </Col>
            )
          )}

          <Col xs={24} sm={24} md={18}>
            <Row gutter={{ xl: 16, xxl: 24 }}>
              {showlist2}
              {isShowMore && hiddenlist2}
            </Row>
          </Col>
        </Row>

        <Row type="flex" gutter={{ xl: 16, xxl: 24 }}>
          {showRow > row + zizhiRow && talentTotal ? (
            <Col xs={24} sm={24} md={6} className="haschild">
              <CompanyItem title="人才资质" number={talentTotal} unit="人" />
            </Col>
          ) : (
            isShowMore &&
            talentTotal !== 0 && (
              <Col xs={24} sm={24} md={6} className="haschild">
                <CompanyItem title="人才资质" number={talentTotal} unit="人" />
              </Col>
            )
          )}
          <Col xs={24} sm={24} md={18}>
            <Row gutter={{ xl: 16, xxl: 24 }}>
              {showlist3}
              {isShowMore && hiddenlist3}
            </Row>
          </Col>
        </Row>

        {allRow > showRow && this.showMoreBotton()}
      </Card>
    );
  }
}
